import {Card, CardContent, CircularProgress, Grid, FormControlLabel,Switch, MenuItem} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import { loadCollectionAttribute } from '../../../reducers/collectionsReducer';
import TextInput from '../../common/Components/TextInput/index';
import MobilierCss from "./css/MobilierCss";

class UpdateGeneral extends Component {

  componentDidMount() {
    loadCollectionAttribute(this.props.dispatch, 'list', 'etats', this.props.statusStore);
  }

  getOptions() {
    if(!this.props.statusStore.list) return []
    return this.props.statusStore.list.map( status => {
      return (
        <MenuItem value={status.uuid} key={status.uuid}>
          {status.libelle}
        </MenuItem>
      )
    })
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, mobiliersStore, onChangeHandler, mobilier,onChangeSwitchHandler, statusStore} = this.props;

    if (!mobilier || !statusStore.list) return (
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <CircularProgress/>
      </Grid>
    );

    const default_status = statusStore.list.filter( s => s.is_default)
    const default_status_uuid = default_status.length > 0 ? default_status[0].uuid : ''

    return (
      <Grid item xs={12} lg={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextInput
                  id="general_num_fiche"
                  type="text"
                  label="N° Fiche"
                  value={mobilier.general_num_fiche}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_num_fiche'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_num_bon_commande"
                  type="text"
                  label="N° Bon de commande"
                  value={mobilier.general_num_bon_commande}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_num_bon_commande'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_num_facturation"
                  type="text"
                  label="N° Facturation"
                  value={mobilier.general_num_facturation}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_num_facturation'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  id="general_immat_proginov"
                  type="text"
                  label="N° Proginov"
                  value={mobilier.general_immat_proginov}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_immat_proginov'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_type_travaux"
                  type="text"
                  label="Type de travaux"
                  value={mobilier.general_type_travaux}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_type_travaux'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_consignation_electrique"
                  type="text"
                  label="Consignation Électrique"
                  value={mobilier.general_consignation_electrique}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_consignation_electrique'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                  <FormControlLabel
                    label="Exploitable"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.general_exploitable}
                        onChange={onChangeSwitchHandler('general_exploitable')}
                      />
                    }
                  />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                    select
                    label="Status"
                    className={classes.input}
                    value={mobilier.status_uuid ? mobilier.status_uuid : default_status_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='status_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions()}
                  </TextInput>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}

UpdateGeneral = withStyles(MobilierCss)(UpdateGeneral);

UpdateGeneral = connect((store) => {
  return {
    statusStore: store.collections.etats
  }
})(UpdateGeneral);

UpdateGeneral.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired
};

export default UpdateGeneral
