import {CircularProgress, Grid, Grow, IconButton, withStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import {Edit, HotTub} from "@material-ui/icons";
import Cell from "@oniti/datatable-material/es/Cell";
import DataTable from "@oniti/datatable-material/es/DataTable";
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from 'react-redux';
import {collectionActions} from "../../../reducers/collectionsReducer";
import UpdateModal from '../Mobilier/Update';
import ContenuSemaineCss from "./css/ContenuSemaineCss";

class ContenuSemaine extends Component {
  state = {
    selectedWeek: this.props.selectedWeek,
    openFullscreenModal: false,
    mobilier_uuid: null,
    listeInterventions: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedWeek !== prevState.selectedWeek) {
      let {dispatch} = nextProps;

      collectionActions(dispatch, 'mobiliers', 'INDEX', {
        params: {
          date_debut: nextProps.selectedWeek.date_debut,
          date_fin: nextProps.selectedWeek.date_fin
        }
      })
    }

    // A partir de la liste de mobiliers reçue dans les props,
    // on construit la liste enrichie en ajoutant le 'type_evenement'
    let listeInterventions = [];
    if (nextProps.mobiliersStore && nextProps.mobiliersStore.list) {
      nextProps.mobiliersStore.list.map(mobilier => {
        if (mobilier.interventions) {
          mobilier.interventions.map(intervention => {
            let result = {...mobilier};

            result.type_intervention = intervention;

            listeInterventions.push(result);
            return null
          });
        }
        return null
      })
    }

    return {selectedWeek: nextProps.selectedWeek, listeInterventions: listeInterventions}
  }

  componentDidMount() {
    let {dispatch} = this.props;

    if (this.props.selectedWeek) collectionActions(dispatch, 'mobiliers', 'INDEX', {
      params: {
        date_debut: this.props.selectedWeek.date_debut,
        date_fin: this.props.selectedWeek.date_fin
      }
    });
  }

  getTranslation(mobilier) {
    const dateColumns = {
      'suivi_date_depose': 'Dépose',
      'suivi_date_terrassement_coulage': 'Terrassement / Coulage',
      'suivi_date_reception_materiel': 'Réception matériel',
      'suivi_date_pose': 'Pose',
      'suivi_date_rembrayage_refection': 'Rembrayage / Réfection',
      'suivi_date_enedis': 'Enedis',
      'suivi_date_rea_raccordement': 'REA Raccordement',
      'suivi_date_controle_ep': 'Contrôle EP',
      'suivi_date_plan_recollement': 'Plan Récolement',
      'suivi_date_reception': 'Réception'
    };

    return Object.keys(dateColumns).map(key => {
      if (key === mobilier.type_intervention) return dateColumns[key];
      return null
    })
  }

  /**
   * Retourne les Cells du datatable
   * @return {[type]} [description]
   */
  getCells() {
    const cellsConfig = [
      {
        datakey: 'marche.libelle',
        title: 'Marché',
        sortable: true,
        searchable: false,
      },
      {
        datakey: 'type_intervention',
        title: 'Intervention',
        sortable: true,
        searchable: false,
        format: this.getTranslation.bind(this)
      },
      {
        datakey: 'geoloc_adresse',
        title: 'Adresse',
        sortable: true,
        searchable: false,
      },
      {
        datakey: 'geoloc_nom_arret',
        title: 'Arrêt',
        sortable: true,
        searchable: false,
      },
    ];
    let cells = cellsConfig.map((conf, index) => <Cell key={index} {...conf} />);

    cells.push(
      <Cell
        key="crud"
        format={this.getActionsButtons.bind(this)}
        sortable={false}
      />
    );

    return cells;
  }

  /**
   * Handler sur edit
   * @param  {[type]} mobilier [description]
   * @return {[type]}      [description]
   */
  onClickEditHandler(mobilier) {
    this.setState({
      openFullscreenModal: true,
      mobilier_uuid: mobilier.uuid
    });
  }

  /**
   * Rendu de la cellule des actions
   * @param  {[type]} mobilier [description]
   * @return {[type]}       [description]
   */
  getActionsButtons(mobilier) {
    const {classes} = this.props;

    return (
      <IconButton
        aria-label="Edit"
        key="edit"
        color="primary"
        onClick={this.onClickEditHandler.bind(this, mobilier)}
        className={classes.button}
        title="Éditer"
      >
        <Edit/>
      </IconButton>
    );
  }

  /**
   * Construction du datatable
   * @returns {*}
   */
  getDataTable() {
    if (this.state.listeInterventions && this.state.listeInterventions.length !== 0) {
      return (
        <DataTable
          showPagination={false}
          datas={this.state.listeInterventions}
        >
          {this.getCells()}
        </DataTable>
      )
    } else {
      const {classes} = this.props;
      return (
        <Grid
          container
          spacing={4}
          justify={'center'}
        >
          <div className={classes.emptyWeekIconContainer}>
            <HotTub className={classes.emptyWeekIcon}/>
          </div>
          <div className={classes.emptyWeekTextContainer}>
            <Typography variant="subtitle1" className={classes.emptyWeekText}>
              Aucun chantier cette semaine !!
            </Typography>
          </div>
        </Grid>
      )
    }
  }

  /**
   * Rendu global
   * @returns {*}
   */
  render() {
    if (!this.props.mobiliersStore.list) return <CircularProgress/>;
    return (
      <Grid container>
        <Grow in={true}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {this.getDataTable()}
          </Grid>
        </Grow>
        <UpdateModal
          onCloseHandler={(mobilier, isSubmited) => {
            let unsafeUpdate = false
            if(JSON.stringify(mobilier) !== JSON.stringify(this.props.mobiliersStore.detail) && !isSubmited){
              unsafeUpdate = true
            }
            if(!unsafeUpdate || window.confirm('Une modification a été apportée sans enregistrement, souhaitez vous quitter malgré tout ?')){
              this.setState({openFullscreenModal: false, mobilier_uuid: null})
              collectionActions(this.props.dispatch, 'mobiliers','RESET_DETAIL')
            }

          }}
          open={this.state.openFullscreenModal}
          mobilier_uuid={this.state.mobilier_uuid}
          date_debut={this.props.selectedWeek.date_debut}
          date_fin={this.props.selectedWeek.date_fin}
        />
      </Grid>
    )
  }
}

ContenuSemaine = connect((store) => {
  return {
    mobiliersStore: store.collections.mobiliers
  }
})(ContenuSemaine);

ContenuSemaine = withStyles(ContenuSemaineCss)(ContenuSemaine);

ContenuSemaine.propTypes = {
  selectedWeek: PropTypes.object.isRequired
};


export default ContenuSemaine
