/**
 * Collections a gérer de manière générique
 */
export default [
  {
    collectionName:'users',
    notifications: [
      {type: 'CREATE', message: 'Utilisateur ajouté'},
      {type: 'UPDATE', message: 'Utilisateur modifié'},
      {type: 'DELETE', message: 'Utilisateur supprimé'},
    ],
  },
  'roles',
  'rights',
  'projets',
  'mobiliers',
  'schemas',
  'marches',
  {
    collectionName:'finitions',
    notifications: [
      {type: 'CREATE', message: 'Finition ajoutée'},
      {type: 'UPDATE', message: 'Finition modifiée'},
      {type: 'DELETE', message: 'Finition supprimée'},
    ],
  },
  'gps',
  'types',
  'gammes',
  'configurations',
  'intitules',
  'designations',
  'code_proginovs',
  'etats',
  {
    collectionName:'schema_images',
    notifications: [
      {type: 'CREATE', message: 'Image ajoutée'},
      {type: 'UPDATE', message: 'Image modifiée'},
      {type: 'DELETE', message: 'Image supprimée'},
    ],
    methods:{
      UPDATE:'post'
    }
  },
  {
    collectionName:'societes',
    notifications: [
      {type: 'CREATE', message: 'Société ajoutée'},
      {type: 'UPDATE', message: 'Société modifiée'},
      {type: 'DELETE', message: 'Société supprimée'},
    ],
    methods:{
      UPDATE:'post'
    }
  },
]
