import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Typography,
  Select,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Person } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { loadCollectionAttribute } from "../../../reducers/collectionsReducer";
import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";
import UserCss from "./css/UserCss";

class User extends Component {
  state = {
    selectedUserSocietes: [],
    currentUserUuid: null,
  };

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "roles",
      this.props.rolesStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "societes",
      this.props.societesStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "etats",
      this.props.statusStore
    );
  }

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const { usersStore } = nextProps;

    if (
      usersStore.detail &&
      prevState.currentUserUuid !== usersStore.detail.uuid
    ) {
      return {
        currentUserUuid: usersStore.detail.uuid,
        selectedUserSocietes: usersStore.detail.societesUuids,
      };
    } else if (usersStore.detail === null) {
      return {
        currentUserUuid: null,
        selectedUserSocietes: [],
      };
    }

    return null;
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return (
      <div>
        <Person /> <span>{"Suppression de l'utilisateur"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes vous sûr de vouloir supprimer cet utilisateur ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(user) {
    return (
      <div>
        <Person />
        <span>
          {!!user
            ? "Édition de l'utilisateur : " +
              user.firstname +
              " " +
              user.lastname
            : "Création d'un nouvel utilisateur"}
        </span>
      </div>
    );
  }

  /**
   * Construction de la liste des rôles
   * @returns {*}
   */
  getRoles() {
    if (!this.props.rolesStore.list) return [];

    return this.props.rolesStore.list.map((role) => {
      return (
        <MenuItem value={role.uuid} key={role.uuid}>
          {role.libelle}
        </MenuItem>
      );
    });
  }

  /**
   * Handler de gestion du state du select Multiple
   */
  onChangeMultipleSelectHandler(e) {
    this.setState({
      selectedUserSocietes: e.target.value,
    });
  }

  onChangeSwitchHandler(name,onChangeHandler,e) {
    onChangeHandler(name,{target:{value:e.target.checked}})
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(user, usersStore, onChangeHandler) {
    let {
        firstname,
        lastname,
        email,
        role_uuid,
        email_realisations,
        email_planifications,
      } = user,
      { selectedUserSocietes } = this.state;
    const { classes } = this.props,
      ITEM_HEIGHT = 48,
      ITEM_PADDING_TOP = 8,
      MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };

    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextInput
            id="firstname"
            label="Prénom"
            value={firstname}
            margin="normal"
            collectionStore={usersStore}
            name="firstname"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="lastname"
            label="Nom"
            value={lastname}
            margin="normal"
            collectionStore={usersStore}
            name="lastname"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="email"
            label="Email"
            value={email}
            margin="normal"
            collectionStore={usersStore}
            name="email"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="role_uuid"
            label="Rôle"
            value={role_uuid}
            margin="normal"
            collectionStore={usersStore}
            name="role_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            {this.getRoles()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            className={classes.formControl}
            style={{ width: "100%", marginTop: "16px", marginBottom: "8px" }}
          >
            <InputLabel htmlFor="select-multiple-checkbox">Sociétés</InputLabel>
            <Select
              multiple
              value={selectedUserSocietes}
              onChange={this.onChangeMultipleSelectHandler.bind(this)}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={(selected) =>
                selected
                  .map((e) => {
                    return this.props.societesStore.list.find(
                      (s) => s.uuid === e
                    ).denomination;
                  })
                  .join(", ")
              }
              MenuProps={MenuProps}
            >
              {this.props.societesStore.list.map((societe) => {
                let checked = undefined;
                if (selectedUserSocietes)
                  checked = selectedUserSocietes.find((societeUuid) => {
                    return societeUuid === societe.uuid;
                  });

                return (
                  <MenuItem key={societe.uuid} value={societe.uuid}>
                    <Checkbox checked={checked !== undefined} color="primary" />
                    <ListItemText primary={societe.denomination} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Email Planification"
            control={
              <Switch
                color="primary"
                checked={email_planifications}
                onChange={this.onChangeSwitchHandler.bind(this,"email_planifications",onChangeHandler)}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Email Réalisation"
            control={
              <Switch
                color="primary"
                checked={email_realisations}
                onChange={this.onChangeSwitchHandler.bind(this,"email_realisations",onChangeHandler)}
              />
            }
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "firstname",
        title: "Prénom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "lastname",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "email",
        title: "Email",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "role.libelle",
        title: "Rôle",
        sortable: true,
        searchable: true,
      },
    ];
  }

  getUsersTitle() {
    const { classes } = this.props;
    return (
      <Typography variant="button" gutterBottom className={classes.usersTitle}>
        Utilisateurs
      </Typography>
    );
  }

  getStatusOptions() {
    if (!this.props.statusStore.list) return [];

    return this.props.statusStore.list.map((status) => {
      return (
        <MenuItem value={status.uuid} key={status.uuid}>
          {status.libelle}
        </MenuItem>
      );
    });
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <Grid container>
        <CollectionCrud
          collectionName={"users"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "firstname",
            rowsPerPageOptions: [25, 50],
          }}
          dataTableExtraNodes={[
            {
              element: this.getUsersTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
          extradatasForm={{
            societesUuids: this.state.selectedUserSocietes,
          }}
        />
      </Grid>
    );
  }
}

User = withStyles(UserCss)(User);

User = connect((store) => {
  return {
    usersStore: store.collections.users,
    rolesStore: store.collections.roles,
    societesStore: store.collections.societes,
    statusStore: store.collections.etats,
  };
})(User);

export default User;
