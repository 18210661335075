import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import { collectionActions } from '../../../reducers/collectionsReducer';
import {buildRoute} from '../../../router/Tools'
import CollectionCrud from '../../common/Components/CollectionCrud';
import MobilierCss from './css/MobilierCss';

class MobilierList extends Component {

  componentDidMount(){
    collectionActions(this.props.dispatch, 'mobiliers', 'INDEX')
  }
  /**
   * retourne la configuration des cellules
   * @return {[marche]} [description]
   */
  getCellsConfig() {
    const {classes} = this.props
    return [
      {
        datakey:'status',
        title:'',
        sortable:false,
        searchable:false,
        format: mobilier => <div title={mobilier.status ? mobilier.status.libelle : ''} className={classes.status +' '+ (mobilier.status ? classes[mobilier.status.class_color] : '') }></div>
      },
      {
        datakey: "general_num_fiche",
        title: "N° Fichie",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "marche_libelle",
        title: "Marché",
        sortable: true,
        searchable: true,
        format: moblier => <Link className={classes.linkMobilier} to={buildRoute('/marches/:uuid', {uuid: moblier.marche_uuid})}>{ moblier.marche_libelle }</Link>
      },
      {
        datakey: "projet_libelle",
        title: "Projet",
        sortable: true,
        searchable: true,
        format: moblier => {
          let links = []
          moblier.projet_uuids.forEach((uuid,index) => {
            links.push(<Link className={classes.linkMobilier} key={uuid} to={buildRoute('/projets/:uuid', {uuid})}>{ moblier.projet_libelle.split(',')[index] }</Link>)
          });
          return (
            <>
              {links}
            </>
          )
        }
      },
      {
        datakey: "geoloc_nom_arret",
        title: "Nom Arrêt",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "geoloc_adresse",
        title: "Adresse",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "geoloc_adresse_complement",
        title: "Adresse Comp.",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "geoloc_code_postal",
        title: "Code Postal",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "geoloc_ville",
        title: "Ville",
        sortable: true,
        searchable: true,
      }
    ]
  }

  getTitle(){
    const {classes} = this.props;
    return (
      <Typography
        variant="button"
        gutterBottom
        className={classes.title}
      >
        Mobiliers
      </Typography>
    )
  }

  /**
   * Fonction de rendu Final
   * @return {[marche]} [description]
   */
  render() {
    const {classes} = this.props
    return (
      <CollectionCrud
        collectionName={'mobiliers'}
        showBtnEdit={false}
        showBtnAdd={false}
        showBtnDelete={false}
        loadDatas={false}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "general_num_fiche",
          rowsPerPageOptions:[50, 25, 10],
          rowClassName: obj => classes.test
        }}
        cellsConfig={this.getCellsConfig()}
        dataTableExtraNodes={[
          {
            element: this.getTitle(),
            position: 'top-left'
          }
        ]}
        persistDatatableOptions={{id:'marche_full'}}
      />
    );
  }
}
MobilierList = connect((store) => {
  return {
    mobiliersStore: store.collections.mobiliers
  }
})(MobilierList);

MobilierList = withStyles(MobilierCss)(MobilierList);
MobilierList = withRouter(MobilierList);

export default MobilierList
