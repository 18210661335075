import {Card, CardContent, CircularProgress, Grid, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextInput from '../../common/Components/TextInput/index';
import MobilierCss from "./css/MobilierCss";

class UpdateSuivi extends Component {
  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, mobiliersStore, onChangeHandler, mobilier} = this.props;

    if (!mobilier) return (
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <CircularProgress/>
      </Grid>
    );

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Dépose
                </Typography>
                <Grid item xs={6}>
                  <TextInput
                    id="suivi_ent_depose"
                    type="text"
                    label="Entreprise"
                    className={classes.input}
                    value={mobilier.suivi_ent_depose}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_ent_depose'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    id="suivi_date_depose"
                    type="date"
                    value={mobilier.suivi_date_depose ? mobilier.suivi_date_depose.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_depose'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Pose
                </Typography>
                <Grid item xs={6}>
                  <TextInput
                    id="suivi_ent_pose"
                    type="text"
                    label="Entreprise"
                    className={classes.input}
                    value={mobilier.suivi_ent_pose}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_ent_pose'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    id="suivi_date_pose"
                    type="date"
                    value={mobilier.suivi_date_pose ? mobilier.suivi_date_pose.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_pose'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Scellement
                </Typography>
                <Grid item xs={6}>
                  <TextInput
                    id="suivi_ent_scellement"
                    type="text"
                    label="Entreprise"
                    className={classes.input}
                    value={mobilier.suivi_ent_scellement}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_ent_scellement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    id="suivi_date_pose"
                    type="date"
                    value={mobilier.suivi_date_scellement ? mobilier.suivi_date_scellement.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_scellement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Finition
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_ent_finition"
                    type="text"
                    label="Entreprise"
                    className={classes.input}
                    value={mobilier.suivi_ent_finition}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_ent_finition'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Raccordement
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_ent_raccordement"
                    type="text"
                    label="Entreprise"
                    className={classes.input}
                    value={mobilier.suivi_ent_raccordement}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_ent_raccordement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Contrôle EP
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_controle_ep"
                    type="date"
                    value={mobilier.suivi_date_controle_ep ? mobilier.suivi_date_controle_ep.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_controle_ep'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Enedis
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_enedis"
                    type="date"
                    value={mobilier.suivi_date_enedis ? mobilier.suivi_date_enedis.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_enedis'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Plan de Récolement
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_plan_recollement"
                    type="date"
                    value={mobilier.suivi_date_plan_recollement ? mobilier.suivi_date_plan_recollement.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_plan_recollement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Réalisation du raccordement
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_rea_raccordement"
                    type="date"
                    value={mobilier.suivi_date_rea_raccordement ? mobilier.suivi_date_rea_raccordement.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_rea_raccordement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Réception
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_reception"
                    type="date"
                    value={mobilier.suivi_date_reception ? mobilier.suivi_date_reception.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_reception'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Réception matériel
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_reception_materiel"
                    type="date"
                    value={mobilier.suivi_date_reception_materiel ? mobilier.suivi_date_reception_materiel.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_reception_materiel'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Rembrayage / Réfection
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_rembrayage_refection"
                    type="date"
                    value={mobilier.suivi_date_rembrayage_refection ? mobilier.suivi_date_rembrayage_refection.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_rembrayage_refection'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Terrassement / Coulage
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    id="suivi_date_terrassement_coulage"
                    type="date"
                    value={mobilier.suivi_date_terrassement_coulage ? mobilier.suivi_date_terrassement_coulage.split(' ')[0] : ''}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_date_terrassement_coulage'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

UpdateSuivi = withStyles(MobilierCss)(UpdateSuivi);

UpdateSuivi = connect((store) => {
  return {}
})(UpdateSuivi);

UpdateSuivi.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired
};

export default UpdateSuivi
