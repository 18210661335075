import {
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography
} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadCollectionAttribute} from "../../../reducers/collectionsReducer";
import TextInput from '../../common/Components/TextInput/index';
import MobilierCss from "./css/MobilierCss";

class UpdateConfigurations extends Component {
  componentDidMount() {
    loadCollectionAttribute(this.props.dispatch, 'list', 'finitions', this.props.finitionsStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'types', this.props.typesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'gammes', this.props.gammesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'configurations', this.props.configurationsStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'intitules', this.props.intitulesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'designations', this.props.designationsStore);
  }

  /**
   * Génère les menus déroulants
   * @param itemStore
   * @returns {any[]}
   */
  getOptions(itemStore,actual_value,relations) {
    if(!itemStore) return []
    return Object.keys(itemStore.list).filter( key => {
      let item = itemStore.list[key]
      return this.isValid(item,actual_value,relations)
    })
    .map(key => {
      let item = itemStore.list[key]
      return (
        <MenuItem value={item.uuid} key={item.uuid}>
          {item.libelle}
        </MenuItem>
      );
    })
  }

  isValid(item,actual_value,relations){
    let is_old_value = item.uuid === actual_value,
        isValidRelation = is_old_value || this.isValidRelation(item,relations)

    return (!item.deleted_at || is_old_value) && (isValidRelation || is_old_value)
  }

  isValidRelation(item,relations){
    if(!item.relations) return true
    let is_valid = false
    item.relations.forEach( r => {
      if(Object.keys(relations).every( key => r[key] === relations[key])){
        is_valid = true
      }
    })
    return is_valid
  }
  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, mobiliersStore, onChangeHandler, onChangeSwitchHandler, mobilier, typesStore,gammesStore,configurationsStore, intitulesStore,designationsStore, finitionsStore} = this.props;

    if (!mobilier || !typesStore.list || !finitionsStore.list || !gammesStore.list || !configurationsStore.list || !intitulesStore.list || !designationsStore.list) return (
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <CircularProgress/>
      </Grid>
    );

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Existant
                </Typography>
                <Grid item xs={12}>
                  <TextInput
                    label="Mobilier Existant"
                    className={classes.input}
                    value={mobilier.config_existant_mobilier}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_existant_mobilier'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Finition"
                    className={classes.input}
                    value={mobilier.config_existant_finition_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_existant_finition_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(finitionsStore)}
                  </TextInput>
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    label="Déposé par"
                    className={classes.input}
                    value={mobilier.config_existant_depose_par}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_existant_depose_par'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}/>
                <Grid item xs={3}>
                  <FormControlLabel
                    label="Raccordé"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.config_existant_is_raccorde}
                        onChange={onChangeSwitchHandler('config_existant_is_raccorde')}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label="À récupérer"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.config_existant_is_a_recuperer}
                        onChange={onChangeSwitchHandler('config_existant_is_a_recuperer')}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    label="À Stocker a l'agence"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.config_existant_is_stocker_agence}
                        onChange={onChangeSwitchHandler('config_existant_is_stocker_agence')}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                      label="Code Immatriculation"
                      className={classes.input}
                      value={mobilier.config_existant_code_immatriculation}
                      margin="normal"
                      collectionStore={mobiliersStore}
                      name='config_existant_code_immatriculation'
                      onChangeHandler={onChangeHandler}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                      label="Ditc"
                      className={classes.input}
                      value={mobilier.general_ditc}
                      margin="normal"
                      collectionStore={mobiliersStore}
                      name='general_ditc'
                      onChangeHandler={onChangeHandler}
                      fullWidth
                    />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Futur
                </Typography>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Type"
                    className={classes.input}
                    value={mobilier.config_futur_type_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_type_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(typesStore,mobilier.config_futur_type_uuid)}
                  </TextInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Famille"
                    className={classes.input}
                    value={mobilier.config_futur_gamme_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_gamme_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(gammesStore,mobilier.config_futur_gamme_uuid,{
                      type_uuid:mobilier.config_futur_type_uuid
                    })}
                  </TextInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Sous Famille"
                    className={classes.input}
                    value={mobilier.config_futur_configuration_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_configuration_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(configurationsStore,mobilier.config_futur_configuration_uuid,{
                      type_uuid: mobilier.config_futur_type_uuid,
                      gamme_uuid: mobilier.config_futur_gamme_uuid
                    })}
                  </TextInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Intitule"
                    className={classes.input}
                    value={mobilier.config_futur_intitule_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_intitule_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(intitulesStore,mobilier.config_futur_intitule_uuid,{
                      type_uuid: mobilier.config_futur_type_uuid,
                      gamme_uuid: mobilier.config_futur_gamme_uuid,
                      configuration_uuid: mobilier.config_futur_configuration_uuid,
                    })}
                  </TextInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Désignation complémentaire"
                    className={classes.input}
                    value={mobilier.config_futur_designation_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_designation_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(designationsStore,mobilier.config_futur_designation_uuid,{
                      type_uuid: mobilier.config_futur_type_uuid,
                      gamme_uuid: mobilier.config_futur_gamme_uuid,
                      configuration_uuid: mobilier.config_futur_configuration_uuid,
                      intitule_uuid: mobilier.config_futur_intitule_uuid,
                    })}
                  </TextInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    select
                    label="Finition"
                    className={classes.input}
                    value={mobilier.config_futur_finition_uuid}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_finition_uuid'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  >
                    {this.getOptions(finitionsStore)}
                  </TextInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="RAL"
                    className={classes.input}
                    value={mobilier.config_futur_ral}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_ral'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Plan de récolement"
                    className={classes.input}
                    value={mobilier.config_futur_plan_recollement}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_plan_recollement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Point de raccordement"
                    className={classes.input}
                    value={mobilier.config_futur_pt_raccordement}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_pt_raccordement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Face I/E"
                    className={classes.input}
                    value={mobilier.config_futur_face_ie}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_face_ie'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="ML Tranchée"
                    className={classes.input}
                    value={mobilier.config_futur_tranchee}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='config_futur_tranchee'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                      label="Code Immatriculation"
                      className={classes.input}
                      value={mobilier.config_futur_code_immatriculation}
                      margin="normal"
                      collectionStore={mobiliersStore}
                      name='config_futur_code_immatriculation'
                      onChangeHandler={onChangeHandler}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="À raccorder"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.config_futur_a_raccorder}
                        onChange={onChangeSwitchHandler('config_futur_a_raccorder')}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="Mobilier Déroulant"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.config_futur_is_mobilier_deroulant}
                        onChange={onChangeSwitchHandler('config_futur_is_mobilier_deroulant')}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Observations
                </Typography>
                <Grid item xs={6}>
                  <TextInput
                    label="Responsable technique"
                    multiline
                    rows={4}
                    rowsMax={4}
                    className={classes.input}
                    value={mobilier.suivi_observations_resp_technique}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_observations_resp_technique'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label="Intervenant"
                    multiline
                    rows={4}
                    rowsMax={4}
                    className={classes.input}
                    value={mobilier.suivi_observations_intervenant}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='suivi_observations_intervenant'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

UpdateConfigurations = withStyles(MobilierCss)(UpdateConfigurations);

UpdateConfigurations = connect((store) => {
  return {
    typesStore: store.collections.types,
    gammesStore: store.collections.gammes,
    configurationsStore: store.collections.configurations,
    intitulesStore: store.collections.intitules,
    designationsStore: store.collections.designations,
    finitionsStore: store.collections.finitions
  }
})(UpdateConfigurations);

UpdateConfigurations.propTypes = {
  mobiliersStore: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onChangeSwitchHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired
};

export default UpdateConfigurations
