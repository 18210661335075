import AuthAdmin from '../../Auth';
import HomeAdmin from '../../Home';
import Parametres from "../../Parametres";
import Projet from '../../Projet';
import Marche from '../../Marche';
import MarcheDetail from '../../Marche/Detail';
import ProjetDetail from '../../Projet/Detail';
import Role from '../../Role';
import User from '../../User';
import MobilierList from '../../Mobilier/List';

export const routes = [
  {path: '/mobiliers', component:MobilierList, exact: true, public: false, right: 'admin-crud-mobiliers'},

  {path: '/marches', component: Marche, exact: true, public: false, right: 'admin-crud-parametres'},
  {path: '/marches/:uuid', component: MarcheDetail, exact: true, public: false, right: 'admin-crud-parametres'},

  {path: '/projets', component: Projet, exact: true, public: false, right: 'admin-crud-parametres'},
  {path: '/projets/:uuid', component: ProjetDetail, exact: true, public: false, right: 'admin-crud-parametres'},

  {path: '/parametres', component: Parametres, exact: true, public: false, right: 'admin-crud-parametres'},

  {path: '/users', component: User, exact: true, public: false, right: 'admin-crud-users'},
  {path: '/roles', component: Role, exact: true, public: false, right: 'admin-crud-roles'},

  {path: '/auth', component: AuthAdmin, exact: false, public: true, noContainer: true},
  {path: '/', component: HomeAdmin, exact: true, public: false, right: 'admin-home'},
];
