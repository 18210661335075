export default theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em',
    color:'#FFFFFF'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  floatright: {
    float: 'right'
  },
  labelInput: {
    position: 'relative',
    marginTop: 0,
    marginBottom: '20px',
    width: '17em',
    marginRight: '1em'
  },
  validButton: {
    height: 40,
    marginRight: theme.spacing(1)
  },
  exportPiquetageButton: {
    marginRight: theme.spacing(1)
  },
  returnButton: {
    float: 'right',
    marginTop: '10px'
  },
  projetTitle: {
    float: 'left',
    marginTop: '1.8em',
    marginRight: '1.5em',
    color: theme.palette.primary.main
  },
  mobiliersTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  projetsTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  inputFile: {
    display: 'none'
  },
  lastColumn: {
    width: '14em',
    minWidth:'14em',
    paddingLeft: '0!important',
    paddingRight: '0!important'
  },
  formControlStatus:{
    width:'15rem'
  },
   // Couleurs status 
  color_red:{
    backgroundColor:'#7f000099',
    color:'white'
  },
  color_orange:{
    backgroundColor:'#c63f1799',
    color:'white'
  },
  color_blue:{
    backgroundColor:'#1976d299',
    color:'white'
  },
  color_green:{
    backgroundColor:'#00675b99',
    color:'white'
  },
  colorPreview:{
    padding:'1em'
  },
  status:{
    width:'1rem',
    height: '1rem'
  }
});
