import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";

export function massUpdateMobiliers(dispatch, projet_uuid, data, cb) {
  axiosClient.post('projets/' + projet_uuid + '/update/mobiliers', data)
    .then(response => {
      addNotification(dispatch, {
        message: "Mobiliers mis à jour",
        bgColor: themeComplement.palette.notifications.success.color,
      });
      dispatch({
        type: 'LIST_FULFILLED',
        collection: 'mobiliers',
        payload: response.data,
      });
      cb();
    }).catch(err => {
    axiosErrorHandler(dispatch, err)
    cb()
  })
}

export function duplicateMobilier(dispatch, projet_uuid, mobilier_uuid, cb) {
  axiosClient.post('projets/' + projet_uuid + '/mobiliers/' + mobilier_uuid + '/duplicate/')
    .then(response => {
      addNotification(dispatch, {
        message: "Mobilier dupliqué",
        bgColor: themeComplement.palette.notifications.success.color,
      });
      dispatch({
        type: 'LIST_FULFILLED',
        collection: 'mobiliers',
        payload: response.data,
      });
      if(cb) cb();
    }).catch(err => {
    axiosErrorHandler(dispatch, err)
    if(cb) cb()
  })
}
