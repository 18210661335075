import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
  withStyles,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { Component } from "react";
import { connect } from "react-redux";
import CollectionCrud from "../../../common/Components/CollectionCrud";
import TextInput from "../../../common/Components/TextInput";
import MobilierCss from "../../Mobilier/css/MobilierCss";

class Status extends Component {
  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle(status) {
    return (
      <div>
        <SettingsIcon />{" "}
        <span>
          {"Suppression du Status : " + (status ? status.libelle : "")}
        </span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes vous sûr de vouloir supprimer ce status ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(status) {
    return (
      <div>
        <SettingsIcon />
        <span>
          {!!status
            ? "Édition du type : " + status.libelle
            : "Création d'un nouveau status"}
        </span>
      </div>
    );
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(status, statusStore, onChangeHandler) {
    let { libelle, is_default, class_color } = status;
    const { classes } = this.props;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextInput
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={statusStore}
            name="libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            label="Défaut"
            control={
              <Switch
                color="primary"
                checked={is_default}
                onChange={this.onSwitchChangeHander.bind(this, onChangeHandler)}
              />
            }
          />
        </Grid>
        <Grid item xs={9}>
          <FormControl className={classes.formControl}>
            <Select
              value={class_color}
              onChange={this.onSelectChangeHander.bind(
                this,
                onChangeHandler,
                "class_color"
              )}
            >
              {this.getOptions()}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  getOptions() {
    const { classes } = this.props;
    return [
      { libelle: "Aucune", value: "color_none" },
      { libelle: "Rouge", value: "color_red" },
      { libelle: "Orange", value: "color_orange" },
      { libelle: "Bleu", value: "color_blue" },
      { libelle: "Vert", value: "color_green" },
    ].map((classOption) => {
      return (
        <MenuItem
          key={classOption.value}
          className={classes[classOption.value]}
          value={classOption.value}
        >
          {classOption.libelle}
        </MenuItem>
      );
    });
  }

  onSwitchChangeHander(onChange, event) {
    onChange("is_default", { target: { value: event.target.checked } });
  }

  onSelectChangeHander(onChange, name, event) {
    onChange(name, event);
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    const { classes } = this.props;
    return [
      {
        datakey: "libelle",
        title: "Libelle",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "class_color",
        title: "Couleur",
        sortable: false,
        searchable: false,
        format: (status) => (
          <div
            className={classes[status.class_color] + " " + classes.colorPreview}
          >
            {" "}
          </div>
        ),
      },
    ];
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <CollectionCrud
        collectionName={"etats"}
        showBtnEdit={true}
        showBtnAdd={true}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent}
        createUpdateModalTitle={this.getCreateUpdateModalTitle}
        createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "libelle",
          rowsPerPageOptions: [10, 25, 50],
        }}
        cellsConfig={this.getCellsConfig()}
      />
    );
  }
}
Status = withStyles(MobilierCss)(Status);

export default Status;
